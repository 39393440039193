<template>
  <div class="user-login">
    <div class="left">
      <img :src="bannerUrl" alt="" />
    </div>
    <div class="right">
      <div class="title">{{ $t('deng-lu') }}</div>
      <div class="flex btn-line">
        <el-button
          :class="tabIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
          >{{ $t('you-xiang') }}</el-button
        >
        <el-button
          :class="tabIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
          >{{ $t('shou-ji-hao') }}</el-button
        >
      </div>
      <div class="login-form">
        <el-form :model="form" :rules="formRules" ref="form">
          <el-form-item
            :label="$t('you-xiang-0')"
            prop="email"
            required
            v-if="tabIndex == 1"
          >
            <el-input
              v-model="form.email"
              :placeholder="$t('qing-shu-ru-you-xiang')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('shou-ji-hao-ma')"
            prop="phone"
            required
            v-if="tabIndex == 2"
          >
            <el-input
              class="phone-input black form-input"
              v-model="form.phone"
              :placeholder="$t('qing-shu-ru-shou-ji-hao')"
              v-if="tabIndex == 2"
            >
              <template slot="prefix">
                <div class="flex-center-start phone-pre">
                  <el-dropdown
                    placement="bottom-start"
                    @command="chooseCountry"
                    class="phone-pre-dropdown"
                  >
                    <span class="text flex-center">
                      {{ form.phonePre }}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <div class="phone-dropdown-item">
                        <el-input
                          v-model="keyword"
                          :placeholder="$t('sou-suo')"
                          @input="searchCountry"
                          @blur="searchCountry"
                        ></el-input>
                        <div
                          v-for="group in showCountryList"
                          :key="group.id"
                          :label="group.title"
                        >
                          <el-dropdown-item
                            v-for="item in group.children"
                            :key="item.key"
                            :command="item.key"
                            >{{ item.title }}</el-dropdown-item
                          >
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('mi-ma')" prop="password" required>
            <el-input
              :type="showPassword ? 'text' : 'password'"
              :placeholder="$t('qing-shu-ru-shou-ji-hao-0')"
              v-model="form.password"
              class="form-input"
            >
              <div
                slot="suffix"
                class="icon-eye flex-center"
                @click="showPassword = !showPassword"
              >
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-center-between">
        <div>
          {{ $t('huan-mei-you-zhang-hao') }}
          <span class="link" @click="toPath('userRegister')">{{
            $t('zhu-ce')
          }}</span>
        </div>
        <div class="link">{{ $t('wang-ji-mi-ma') }}</div>
      </div>

      <el-button class="login-btn" @click="submit">{{
        $t('deng-lu-0')
      }}</el-button>
    </div>
  </div>
</template>
<script>
import { login } from '@/api/login'
import { setUserId, setToken, setUserType } from '@/utils/auth'
export default {
  data() {
    return {
      bannerUrl: require('@/assets/imgs/login-banner.png'),
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      tabIndex: 1,
      form: {
        username: '',
        phone: '',
        email: '',
        phonePre: '+86',
        password: ''
      },
      showPassword: false,
      keyword: '',
      showCountryList: [],
      formRules: {
        phone: [
          { required: true, message: this.$t('qing-shu-ru-shou-ji-hao-1') }
        ],
        email: [
          { required: true, message: this.$t('qing-shu-ru-you-xiang-0') }
        ],
        password: [{ required: true, message: this.$t('qing-shu-ru-mi-ma') }]
      }
    }
  },
  computed: {
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  mounted() {
    this.showCountryList = [].concat(this.countryList)
  },
  methods: {
    changeTab(i) {
      this.tabIndex = i
    },
    searchCountry() {
      if (!this.keyword) {
        this.showCountryList = [].concat(this.countryList)
      } else {
        let allList = JSON.parse(JSON.stringify(this.countryList))
        this.showCountryList = allList.map((v) => {
          v.children = v.children.filter((val) => {
            return (
              val.key.indexOf(this.keyword) > -1 ||
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          return v
        })
      }
    },
    chooseCountry(data) {
      this.form.phonePre = data
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          login({
            Account:
              this.tabIndex === 1
                ? this.form.email
                : `${this.form.phonePre}${this.form.phone}`,
            Password: this.form.password
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('deng-lu-cheng-gong'))
              let data = res.data
              this.$store.state.userType = data.business
              this.$store.state.isLogin = true
              setUserId(data.id)
              setUserType(data.business)
              setToken(data.token)
              this.$router.push({
                name: data.business == 1 ? 'dashboard' : 'index'
              })
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>